import { Col, DatePicker, Row, Spin, Typography, message } from "antd";
import Container from "components/Common/Container";
import TImage from "components/Common/TImage";
import FeaturedPageSEO from "components/PublicPages/Featured/FeaturedSEO";
import moment from "dayjs";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import DEFAULT_PROFILE_PIC from "public/images/common/default_profile_pic.jpg";
import React, { useEffect, useState } from "react";
import { useCustomOrgUser, useOrg } from "services/ApiHooks";
import { getAvailableUsers } from "services/public-services";
import { Analytics } from "utils/Analytics/AnalyticsHelper";
import FeaturedFooter from "./FeaturedFooter";
import FeaturedTopNav from "./FeaturedHeader";
import FeaturedHeroSection from "./FeaturedHeroSection";
import OrgDiscountPopup from "./OrgDiscountPopup/OrgDiscountPopup";

export interface FeaturedUser {
  id: string;
  profilePic: string;
  full_name: string;
  username: string;
  title: string;
  tags: string[];
}

export interface CustomPageConfig {
  bannerColor: string;
  title: string;
  subTitle: string;
  logo: string;
  users: FeaturedUser[];
  textColor: string;
  showTopBar: boolean;
  showHeader?: boolean;
}

const { Paragraph, Text } = Typography;

interface FeaturedProps {
  group?: string;
}

const { RangePicker } = DatePicker;

const Featured: React.FC<FeaturedProps> = () => {
  const router = useRouter();
  const [selectedTag, setSelectedTag] = useState<string>("");
  const [filter, setFilters] = useState<any>();
  const { org: config, isLoading: loading } = useOrg();
  const { orgUsers: users, isLoading: userLoading } = useCustomOrgUser();
  const [filterIds, setFilterIds] = useState<any>([]);
  const listing_config = config?.listing_config;
  const { coupon_code } = router.query;

  const profileCardVariants = {
    hidden: {
      opacity: 0,
      y: 200,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const getFilteredUsers = () => {
    return selectedTag && selectedTag !== "All"
      ? users?.filter((data) => {
          return filterIds?.length > 0
            ? data?.tags?.includes(selectedTag) &&
                filterIds.includes(Number(data?.id))
            : data?.tags?.includes(selectedTag);
        })
      : filterIds?.length > 0
        ? users?.filter((data) => {
            return filterIds.includes(Number(data?.id));
          })
        : users;
  };

  const checkAvailable = async (value, date) => {
    if (value) {
      try {
        const res = await getAvailableUsers(
          date[0].replace(/ /g, "T"),
          date[1].replace(/ /g, "T")
        );
        setFilterIds(res);
      } catch {
        message.error("Something went wrong");
        setFilterIds([]);
      }
    } else {
      setFilterIds([]);
    }
  };

  const getServiceUrl = (user): string => {
    if (listing_config?.redirect_to_topmate) {
      return `https://topmate.io/${user?.username}${window.location.search}${listing_config?.org_coupon?.coupon_code ? `&coupon_code=${listing_config?.org_coupon?.coupon_code}` : ""}`;
    }
    const baseUrl = `/${user?.username}`;
    if (listing_config?.org_coupon?.coupon_code) {
      return `${baseUrl}?coupon_code=${listing_config?.org_coupon?.coupon_code}`;
    } else {
      if (coupon_code) return `${baseUrl}?coupon_code=${coupon_code}`;
      else return baseUrl;
    }
  };

  useEffect(() => {
    if (listing_config && listing_config?.sections?.filters?.visible) {
      setFilters(["All", ...listing_config.sections.filters.primary]);
      setSelectedTag("All");
    }
  }, [config]);

  const FeaturedPerson = () => {
    return (
      <Row
        gutter={[
          { xs: 10, sm: 16, md: 24, lg: 32 },
          { xs: 20, sm: 16, md: 24, lg: 32 },
        ]}
      >
        {getFilteredUsers()?.map((data, idx) => {
          return (
            <Col xs={12} md={8} lg={6} key={data?.id}>
              <motion.div variants={profileCardVariants}>
                <Paragraph
                  className={
                    listing_config?.show_short_bio
                      ? "featured-card-wrap featured-with-bio"
                      : "featured-card-wrap"
                  }
                  onClick={(): any => {
                    Analytics.track("profile_clicked", {
                      index: idx + 1,
                      filter_selected: selectedTag,
                      username: data?.username,
                      expert_id: data?.id,
                      category: data?.tags,
                    });
                    router.push(getServiceUrl(data));
                    // window.open(`/${data?.username}`);
                  }}
                >
                  <Paragraph className="featured-card">
                    <TImage
                      className="featured-card-image"
                      alt="profile"
                      src={data?.profilePic || DEFAULT_PROFILE_PIC}
                      width={100}
                      unoptimized
                      loading="eager"
                      priority
                    />
                    <Paragraph className="details">
                      <Paragraph className="title" ellipsis={{ rows: 1 }}>
                        {data?.full_name}
                      </Paragraph>
                      <Paragraph className="description" ellipsis={{ rows: 1 }}>
                        {data?.tags?.map((tag, idx) => {
                          return idx === data?.tags?.length - 1
                            ? `${tag}`
                            : `${tag}, `;
                        })}
                      </Paragraph>
                      {listing_config?.show_short_bio && (
                        <Paragraph className="short-bio" ellipsis={{ rows: 2 }}>
                          {data?.title}
                        </Paragraph>
                      )}
                    </Paragraph>
                  </Paragraph>
                </Paragraph>
              </motion.div>
            </Col>
          );
        })}
      </Row>
    );
  };

  return (
    <Spin spinning={loading || userLoading} style={{ width: "100%" }}>
      {listing_config?.meta_data && (
        <FeaturedPageSEO seoData={listing_config?.meta_data} />
      )}
      <Container type="fluid" className={"featured-page"}>
        {listing_config?.sections?.nav?.visible && <FeaturedTopNav />}
        {listing_config?.sections?.hero?.visible && <FeaturedHeroSection />}
        <Container
          className="featured-container"
          style={{
            maxWidth: "none",
            backgroundColor:
              config?.listing_config?.listing_section_background_color,
          }}
        >
          <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }}>
            <Row justify="space-between" gutter={8}>
              <Col
                md={config?.listing_config?.show_date_filter ? 16 : 24}
                xs={24}
                className="filter-container"
              >
                {filter?.length > 0 && (
                  <Text className="flex-center filter-tabs">
                    {filter.map((data, idx) => {
                      return (
                        <Col
                          onClick={() => {
                            setSelectedTag(data),
                              Analytics.track("page filter clicked", {
                                filter_index: idx + 1,
                                filter_type: data,
                              });
                          }}
                          key={data}
                        >
                          <Paragraph
                            className={
                              selectedTag === data
                                ? "selected-tab filter-data"
                                : "filter-data"
                            }
                          >
                            {data}
                          </Paragraph>
                        </Col>
                      );
                    })}
                  </Text>
                )}
              </Col>
              <Col className="date-filter">
                {config?.listing_config?.show_date_filter && (
                  <RangePicker
                    showTime
                    format="YYYY-MM-DD HH:mm"
                    onChange={(value, date) => checkAvailable(value, date)}
                    disabledDate={(current) => {
                      let customDate = moment().format("YYYY-MM-DD");
                      return (
                        current && current < moment(customDate, "YYYY-MM-DD")
                      );
                    }}
                  />
                )}
              </Col>
            </Row>
            <motion.div
              variants={profileCardVariants}
              initial="hidden"
              animate="visible"
            >
              <FeaturedPerson />
            </motion.div>
          </Col>
        </Container>
        {listing_config?.sections?.footer?.visible && <FeaturedFooter />}
        {listing_config?.org_coupon && <OrgDiscountPopup />}
      </Container>
    </Spin>
  );
};
export default Featured;
